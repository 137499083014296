import React from 'react';
import Navbar from './Navbar';





function Header() {
    return (
        <div id='main'>
            <Navbar/>
            <div className='name'>
           
            </div>
                <br></br>
                <div class="vision">
                  <div class="inner">
                <div class="price1">
                <h2> <span>BUY BID SELL </span></h2></div><div class="price"><h2><p><span class="metal-flash">STUNNING NAMES FOR YOUR BUSINESS  </span></p><p></p><span>for inquiries contact our sales team</span></h2>
                </div>
                
              
  




</div>
</div>
</div>
        
    )
}

export default Header;