import React from 'react';







export function Specs(props) {
    

    return (
        <div id='specs'>
   
        
          <div class="copyright">Copyright © 2023 Mighty.Domains • All rights reserved     <br></br><br></br><i class="fa-solid fa-envelope"></i>  sales@mighty.domains</div>
          
        </div>
    )
}

export default Specs;
